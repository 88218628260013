import {uploadWebm} from "@/utils/apis";
import { Loading } from "element-ui"
import Hls from "hls.js";
import Flv from "flv.js";
import {hlsBaseUrl, wssBaseUrl} from "@/utils/common";

let loading;
export const barrage = [
    "这个多少",
    "辛苦辛苦！",
    "成色都是写的相当保守的，实物成色至少98新往上，宝贝们所见即所得，放心拍！",
    "今天想买一下",
    "播完再下",
    "是几新的？？",
    "喜欢",
    "可是这个我也喜欢",
    "为什么两只手都不带手套",
    "想要",
    "好看",
    "就这个",
    "褪色了嘛",
    "吓死我了 哈哈哈",
    "我拍了 是我拍到的吗",
    "是我的吗",
    "拍了拍了",
    "快快快",
    "开开开",
    "这帅哥真帅",
    "不喜欢",
    "嘻嘻嘻",
    "没上啊",
    "今天拍好划算",
    "不用纠结了",
    "直接抢",
    "对",
    "福利真给力！",
    "1",
    "爱了爱了"
];

export const addBarrage = (content) => {
    return {
        timer: void 0,
        index: -1,
        status: 0, //0停止，1正在执行
        start(list, user) {
            this.status == 0 && (this.status == 1);
            this.stop();
            this.timer = setInterval(() => {
              const userIndex = Math.floor(Math.random() * barrage.length);
              list.push({
                  avatar: "",
                  barrage: content[++this.index] || content[(this.index = 0)],
                  origin: 0,
                  nickname: barrage[userIndex],
                  send_msg_user_id: 0,
                  timestamp: +new Date()
              })
            }, 2000)
        },
        stop() {
            this.status = 0;
            this.timer && window.clearInterval(this.timer);
        }
    }
}

export const clearVideo = (com) => {
    const parentNode = com.$refs.liveVideo;
    parentNode && com.$set(parentNode, "innerHTML", "");
    com.stream && com.stream.getTracks().forEach(item => item.stop && item.stop())
    return parentNode;
}

const validateRes = (res, com) => {
    const valid = res.code == 200;
    !valid && com.$message.error(res.msg);
    return valid;
}
export const getBulletChatData = (com, id) => new Promise((resolve, reject) =>
    com.$http.withoutGetBy(com.$api.studentLiveGetBulletChat, { goods_id: id } , res => {
        if(!validateRes(res, com)) return;
        resolve(res.data);
        // resolve([])
    }
))
export const getSystemBulletChat = (com) => {
  getBulletChatData(com, 0).then(data => {
    const lastScript = com.scriptCarousel && com.scriptCarousel.at(-1);
    if(!lastScript) return;
    const list = data.map(item => item.content);
    com.randomBarrage.push(addBarrage(list));
  })
}
export const getGoodsData = (com) => new Promise((resolve, reject) =>
    com.$http.withoutGetBy(com.$api.studentLiveGetGoods, { paging: 0 }, res => {
        if(!validateRes(res, com)) return;
        const list = res.data.list ? res.data.list.reduce((list, item) => list.concat({
            id: item.id,
            goods_id: item.id,
            // status: item.status[1] || item.status[2] ? 1 : 0,
            status: 0,
            releaseLoading: false,
            loseLoading: false,
            goods_info: {
                id: item.id,
                goods_class_id: item.id,
                goods_name: item.goods_name,
                goods_market_price: item.daily_price,
                goods_discount_price: item.live_price,
                goods_master_img: item.src.src
            }
        }), []) : [];
        !com.listInfo && (com.$set(com, "listInfo", {}));
        !com.listInfo.goodsList ? com.$set(com.listInfo, "goodsList", list) : com.listInfo.goodsList.forEach(item => {
            item.releaseLoading = false;
            item.loseLoading = false;
        })
        // const addedGoodsList = list.filter(item => item.status);
        // com.$set(com, "addedGoodsList", addedGoodsList);
        com.setGoodsList();
        resolve(list);

        // resolve([
        //     {
        //     "id": 3, //数据id
        //     "goods_id": 1, //商品id
        //     "goods_name": "name1", //商品名称
        //     "daily_price": "123.12", //日常价格
        //     "live_price": "12.50", //直播间价格
        //     "status": 1, //状态：......
        //     "src": {
        //     "src": "http://country.competition.yike/uploads/kefu/goods_image/20200930/735dd56fca47864639e147b622b297fa.jpg",
        //     "url": "uploads/kefu/goods_image/20200930/735dd56fca47864639e147b622b297fa.jpg"
        //     }
        //     }
        //     ])
    }
))
export const getScriptData = (com) => new Promise((resolve, reject) =>
    com.$http.withoutGet(com.$api.studentLiveGetScript, res => {
        if(!validateRes(res, com)) return;
        let goods;
        res.data.list && res.data.list.forEach(item => {
            item.startTime = item.start_time;
            item.endTime = item.end_time;
            item.sleepTime = item.sleep_time;
            item.goods_id && (!goods || goods && goods.goods_id != item.goods_id) && com.listInfo && com.listInfo.goodsList && (goods = com.listInfo.goodsList.find(goods => goods.goods_id == item.goods_id));
            goods && (item.good_info = goods.goods_info);
            if(item.type_content == "商品问答") {
              getBulletChatData(com, item.goods_id).then(data => {
                // item.randomBulletChat = data.map(bullet => addBarrage(bullet.content, item.sleepTime / bullet.count, bullet.count));
                const list = data.map(bullet => bullet.content);
                list.length > 0 && (item.randomBulletChat = [addBarrage(list)]);
              })
            }
        })
        com.$set(com, "viewInfo", { onlive: { total_time: { total_time: res.data.list.at(-1).endTime } } });
        com.$set(com, "scriptCarousel", res.data.list);
        resolve(res.data.list || []);
        // resolve([
        //     {
        //     "type_content": "脚本类型1", //脚本类型名称
        //     "script_type": 1, //隐藏脚本类型：1=普通脚本，5=商品脚本
        //     "start_time": 0, //开始时间：秒，自行转化
        //     "end_time": 71, //结束时间：秒，自行转化
        //     "sleep_time": 71, //时间间隔
        //     "goods_id": 0, //商品id
        //     "script_content": "我是普通脚本类型1" //脚本内容
        //     },
        //     {
        //     "type_content": "", //脚本类型名称
        //     "script_type": 1, //隐藏脚本类型：1=普通脚本，5=商品脚本
        //     "start_time": 71, //开始时间：秒，自行转化
        //     "end_time": 74, //结束时间：秒，自行转化
        //     "sleep_time": 3, //时间间隔
        //     "goods_id": 0, //商品id
        //     "script_content": "我是普通脚本类型1的子级1" //脚本内容
        //     },
        //     {
        //     "type_content": "", //脚本类型名称
        //     "script_type": 1, //隐藏脚本类型：1=普通脚本，5=商品脚本
        //     "start_time": 74, //开始时间：秒，自行转化
        //     "end_time": 77, //结束时间：秒，自行转化
        //     "sleep_time": 3, //时间间隔
        //     "goods_id": 0, //商品id
        //     "script_content": "我是普通脚本类型1的子级2" //脚本内容
        //     },
        //     {
        //     "type_content": "商品讲解/上架商品", //脚本类型名称
        //     "script_type": 5, //隐藏脚本类型：1=普通脚本，5=商品脚本
        //     "start_time": 77, //开始时间：秒，自行转化
        //     "end_time": 81, //结束时间：秒，自行转化
        //     "sleep_time": 4, //时间间隔
        //     "goods_id": 1, //商品id
        //     "script_content": "我是商品讲解/上架商品" //脚本内容
        //     },
        //     {
        //     "type_content": "商品卖点", //脚本类型名称
        //     "script_type": 5, //隐藏脚本类型：1=普通脚本，5=商品脚本
        //     "start_time": 81, //开始时间：秒，自行转化
        //     "end_time": 82, //结束时间：秒，自行转化
        //     "sleep_time": 1, //时间间隔
        //     "goods_id": 1, //商品id
        //     "script_content": "我是商品卖点" //脚本内容
        //     },
        //     {
        //     "type_content": "优惠券/发布优惠券", //脚本类型名称
        //     "script_type": 5, //隐藏脚本类型：1=普通脚本，5=商品脚本
        //     "start_time": 82, //开始时间：秒，自行转化
        //     "end_time": 83, //结束时间：秒，自行转化
        //     "sleep_time": 1, //时间间隔
        //     "goods_id": 1, //商品id
        //     "script_content": "我是优惠券/发布优惠券" //脚本内容
        //     },
        //     {
        //     "type_content": "商品问答", //脚本类型名称
        //     "script_type": 5, //隐藏脚本类型：1=普通脚本，5=商品脚本
        //     "start_time": 83, //开始时间：秒，自行转化
        //     "end_time": 84, //结束时间：秒，自行转化
        //     "sleep_time": 1, //时间间隔
        //     "goods_id": 1, //商品id
        //     "script_content": "我是商品问答" //脚本内容
        //     },
        //     {
        //     "type_content": "", //脚本类型名称
        //     "script_type": 5, //隐藏脚本类型：1=普通脚本，5=商品脚本
        //     "start_time": 84, //开始时间：秒，自行转化
        //     "end_time": 85, //结束时间：秒，自行转化
        //     "sleep_time": 1, //时间间隔
        //     "goods_id": 1, //商品id
        //     "script_content": "我是商品问答的子级" //脚本内容
        //     }
        //     ])
    }
))
export const getCouponData = (com) => new Promise((resolve, reject) =>
    com.$http.withoutGet(com.$api.studentLiveGetCoupon, res => {
        if(!validateRes(res, com)) return;
        const list = res.data.list ? res.data.list.map(item => ({
            couponType: item.coupon_type == "指定商品折扣券" ? 2 : 1,
            type: item.coupon_type == "指定商品折扣券" ? 2 : 1,
            couponName: item.name,
            everyoneCount: item.limit,
            // status: item.status,
            status: 0,
            coupon_student_id: item.id,
            goods_id: item.sale_goods_id,
            coupon_full: item.money,
            coupon_minus: item.threshold,
            number: item.circulation,
            coupon_id: item.id,
            loading: false
        })) : [];
        !com.listInfo && (com.$set(com, "listInfo", {}));
        !com.listInfo.couponList && (com.$set(com.listInfo, "couponList", []));
        com.listInfo.couponList.splice(0, com.listInfo.couponList.length, ...list);
        resolve(res.data.list || []);
    }
))

export const getRoomDetail = (com, id) => {
    com.$http.withoutGetBy(com.$api.studentLiveRoomDetail, { id }, res => {
        if(!validateRes(res, com)) return;
        const userInfo = res.data;
        document.title = `${userInfo.nickname}的直播间`
        com.$set(com, "userInfo", userInfo);
    })
}

export const startRecord = async(com, videoEl) => {
    if(!videoEl) {
        this.$message.error("未获取到视频容器");
        return;
    }
    return new Promise(async(resolve, reject) => {
        let mediaRecorder;
        let audioStream;
        let videoStream;
        let screen_stream;
        try {
            // audioStream = await navigator.mediaDevices.getUserMedia({audio: true})
            videoStream = await navigator.mediaDevices.getDisplayMedia({video: {cursor: "always"}, audio: false})
            const videoTracks = videoStream.getVideoTracks();
            // const audioTracks = audioStream.getAudioTracks()
            const audioTracks = videoEl.captureStream().getAudioTracks()[0];
            if(!audioTracks) {
                com.$message.error("未获取到音频流");
                return;
            }
            screen_stream = new MediaStream([...videoTracks, audioTracks]);
            const mimeType = 'video/webm; codecs="vp8"';
            mediaRecorder = new MediaRecorder(screen_stream, { mimeType });
            mediaRecorder.onstart = () => {
                resolve(true);
                mediaRecorder.pause();
                setTimeout(() => mediaRecorder.resume(), 4000);
            }
            mediaRecorder.ondataavailable = async (event) => {
                console.log("停止了")
                console.log(event.data.size);
                if (event.data.size > 0) {
                    com.$set(com, "chunks", []);
                    com.chunks.push(event.data)
                }
            }
            videoStream.getVideoTracks()[0].addEventListener("ended", e => {
                com.sendUpdateLiveState(2);
            })
            mediaRecorder.start();
            com.mediaRecorder && com.mediaRecorder.stop && com.mediaRecorder.stop();
            com.$set(com, "mediaRecorder", mediaRecorder)
            com.$set(com, "screen_stream", screen_stream)
            // com.mediaRecorder.start();
        }catch(e) {
            console.error("Error starting recording:", e)
            // console.log(screen_stream);
            com.flag = true;
        }
    })
}
export const stopRecord = (com, callback) => {
    try {
        if(!com.mediaRecorder) {
            com.$message.error("未获取媒体流")
            return;
        }
        let timer;
        com.endLiveTimestamp = Math.floor(+new Date() / 1000);
        let progress = 0;
        const getHint = (progress) => {
            progress = progress;
            return `正在保存并上传直播视频，请勿刷新或关闭 ${progress}%`;
        };
        const pushProgress = (progress) => {
            const el = loading.$el && loading.$el.querySelector(".el-loading-text");
            el.innerText = getHint(progress);
            console.log(progress);
        }
        loading && loading.close();
        loading = Loading.service({ fullscreen: true, text: getHint(0) })
        com.mediaRecorder && com.mediaRecorder.stop();
        console.log(com.chunks);
        timer = setTimeout(() => {
            progress === 0 ? stopHandler() : clearTimeout(timer);
        }, 30000)
        async function stopHandler () {
            console.log("停止了么？")
            console.log("chunks", com.chunks)
            console.log("停止中。。。。。")
            const blob = new Blob(com.chunks, {type: "video/webm"})
            const fileSize = blob.size
            const chunkSize = 1024 * 1024
            const random = com.$common.random_str(8)
            const total = Math.ceil(fileSize / chunkSize)
            console.log("总数：", total)
            let start = 0
            let index = 0;
            console.log(start, fileSize, index);
            while (start < fileSize) {
                console.log("valid: token: ", localStorage.getItem("token"));
                if(!localStorage.getItem("token")) {
                    throw new Error("中断上传")
                }
                console.log("create: formData");
                const end = Math.min(start + chunkSize, fileSize)
                const chunk = blob.slice(start, end)
                const formData = new FormData()
                formData.append("file", chunk, "record.webm")
                formData.append("index", index)
                formData.append("total", total)
                formData.append("random", random)
                formData.append("ext", "webm")
                console.log("upload request");
                try {
                    progress = 1;
                    console.log(progress);
                    const response = await uploadWebm(formData)
                    response.url && (com.videoUrl = response.url)
                    // console.log("res", response.url)
                    console.log("res", response)
                } catch (e) {
                    console.log("Error: ", e)
                }
                start = end
                index++;
                console.log("当前进度：", index)
                pushProgress(Math.floor(100 / total * index))
                console.log(progress);
            }
            timer && clearTimeout(timer);
            com.$message.success("上传成功");
            com.chunks = []
            loading.close();
            callback && callback()
        }
        com.mediaRecorder.onstop = stopHandler;
    }catch(e) {
        console.error("Error starting recording:", e)
    }
}

export const sendCoupon = (com, params) => {
  com.$http.withoutPost(com.$api.studentLiveSendCoupon, params, res => {
    if(!validateRes(res, com) || !com.listInfo || !com.listInfo.couponList) return;
    const index = com.listInfo.couponList.findIndex(item => item.coupon_id == params.id);
    if(index === -1) return;
    com.listInfo.couponList[index].status = 1;
    com.listInfo.couponList[index].loading = false;
    // getCouponData(com);
  })
}

export const operationGoods = (com, params) => {
  com.$http.withoutPost(com.$api.studentLiveSendGoods, params, res => {
    if(!validateRes(res, com) || !com.listInfo || !com.listInfo.goodsList) return;
    const index = com.listInfo.goodsList.findIndex(item => item.goods_id == params.goods_id);
    if(index === -1) return;
    com.listInfo.goodsList[index].status = params.status;
    com.listInfo.goodsList[index].releaseLoading = false;
    com.listInfo.goodsList[index].loseLoading = false;
    const addedGoodsList = com.listInfo.goodsList.filter(item => item.status);
    com.$set(com, "addedGoodsList", addedGoodsList);
    com.setGoodsList();
    // getGoodsData(com);
  })
}

export const endLive = (com) => {
    return new Promise((resolve, reject) => {
        const params = {
            stop_time: com.endLiveTimestamp - com.startLiveTimestamp,
            video_url: com.videoUrl,
            barrage: com.oldBulletChat
        };
        com.$http.withoutPost(com.$api.studentLiveStop, params, res => {
            if(!validateRes(res, com)) {
                resolve(false);
                return;
            };
            resolve(true);
        })
    })
}

export const stopAllStream = async(com) => {
    com.screen_stream && com.screen_stream.getTracks && com.screen_stream.getTracks().forEach(item => item.stop());
}
let playTimer;
export const playVideo = async(com,  videoEl, resolve) => {
    // try {
    // const wssUrl = await wssBaseUrl()
    // if(Flv.isSupported()) {
    //     try {
    //         com.hls && com.hls.destroy && com.hls.destroy();
    //     }catch(e) {}
    //     const flvPlayer = Flv.createPlayer({
    //         type: "flv",
    //         url: wssUrl,
    //         // url: "wss://47.110.245.32:4433/live/xiaozhou.ts",
    //         isLive: true,
    //         cors: true,
    //         hasAudio: true,
    //         hasVideo: true,
    //         withCredentials: false
    //     }, {enableStashBuffer: true});
    //     flvPlayer.on(Flv.Events.ERROR, (type, data) => {
    //         const handlers = {
    //             [Flv.ErrorTypes.NETWORK_ERROR]: "网络异常",
    //             [Flv.ErrorTypes.MEDIA_ERROR]: "媒体异常",
    //             [Flv.ErrorTypes.OTHER_ERROR]: "直播出现异常"
    //         }
    //         const message = handlers[type];
    //         message && com.$message.warning(`${message}, 请尝试重试操作或刷新页面`);
    //         message && playTimer && clearTimeout(playTimer);
    //         console.log(message, type, Flv.ErrorTypes);
    //         com.flag = true;
    //     });
    //     flvPlayer.on(Flv.Events.METADATA_ARRIVED, e => {
    //         playTimer && clearTimeout(playTimer);
    //         resolve(videoEl);
    //     })
    //     flvPlayer.attachMediaElement(videoEl);
    //     flvPlayer.load();
    //     flvPlayer.play();
    //     com.hls = flvPlayer;
    //     playTimer = setTimeout(() => {
    //         com.hls && com.hls.destroy();
    //         com.flag = true;
    //         com.loadingAn = false;
    //         com.$message.error("拉流超时，请尝试重新操作");
    //     }, 5000)
    // }
    // return;
    if (Hls.isSupported()) {
        let hls = new Hls({
            liveSyncDurationCount: 2,
            liveMaxLatencyDurationCount: 3,
        })
        const src = await hlsBaseUrl();
        console.log("src", src)
        hls.loadSource(src)
        hls.attachMedia(videoEl)
        hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
            videoEl.play()
            resolve(videoEl)
        })
        hls.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal) {
                switch (data.type) {
                    case Hls.ErrorTypes.NETWORK_ERROR:
                        console.log("网络异常的问题")
                        com.$message.warning("还没开始直播，请先在手机端开启直播")
                        //重载
                        hls.startLoad()
                        break;
                    case Hls.ErrorTypes.MEDIA_ERROR:
                        console.log("Media Error:", data)
                        com.$message.warning("设备异常")
                        hls.recoverMediaError()
                    default:
                        hls.destroy()
                        break;
                }
                com.flag = true;
                com.stopLive(true);
            }
        })
        com.hls = hls;
    } else if (videoEl.canPlayType("application/vnd.apple.mpegurl")) {
        videoEl.src = src
        videoEl.addEventListener("loadedmetadata", () => {
            videoEl.play();
            resolve(videoEl);
        })
    }
    // }finally {
    //     com.$message.error("拉流异常, 请先在移动端进行推流或检查地址是否可用");
    //     com.flag = true;
    // }
}
